<template>
<!-- <div href="/taskmanager">回到主页</div> -->
<div id="back">
    <a href="/taskmanager" style="">--回到主页---</a>  
</div>

<div id="doc">
</div>

</template>

<script>
import configure_options from '../configure'
const axios = require('axios').default;
export default {
  name: 'doc',
  props: {
    msg: String
  }
  ,mounted(){


       var _this = this
    // _this.todaytabstatus = "tabactive"
    // _this.tomorrowtabstatus = "no"
       var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

    axios.get(serverhost + "/doc").then(
     response=>{
       var data = response.data
       _this.goaltreedata = data
        // goaltreedata = data
        document.getElementById("doc").innerHTML = data
        console.log(data)
        document
        .getElementsByTagName("img")[0].style.width="100px";
     }
     ).catch(error=>{console.log(error)})
    

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  width: 100px
}
#back{
  /* position: absolute;; */
  left: 10%;
  width: 200px;

  /* display:block; */
}
#doc{
    margin-left:150px;
    /* background: #e5e5ec; */
    /* margin-right: 150px; */
}
#doc /deep/ h1 {
    color: red;
    text-align: left;
    font-family: cursive;
}
#doc /deep/ h2 {
    color: rgb(126, 12, 233);
    text-align: left;
}
#doc /deep/ h3 {
    color: blue;
    text-align: left;
}
#doc /deep/ pre{
    text-align: left;
}
</style>
